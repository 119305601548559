<template>
    <div class="page bg-white">
        <el-tabs type="card" v-model="activeName">
            <el-tab-pane label="藏品数字资源管理" name="0">
                <Resource v-if="activeName == 0"></Resource>
            </el-tab-pane>
            <el-tab-pane label="典藏管理" name="1">
                <Collection v-if="activeName == 1"></Collection>
            </el-tab-pane>
            <el-tab-pane label="档案资产管理" name="2">
                <Record v-if="activeName == 2"></Record>
            </el-tab-pane>
            <el-tab-pane label="系统设置" name="3">
                <System v-if="activeName == 3"></System>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Resource from './resource'
    import Collection from './collection'
    import Record from './record'
    import System from './system'
    export default {
        components: {Resource, Collection, Record, System},
        data() {
            return {
                activeName: '0',
            }
        },

    }
</script>

<style scoped>
    .saveBtn {
        position: fixed;
        bottom: 20px;
        left: 55%;
    }
</style>
